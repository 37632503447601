.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.hideScrollBar {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.hideScrollBar::-webkit-scrollbar {
  display: none;
}

/* using CSS Custom Properties */
:root {
  --main-font-color: #0e2744;
  --accent-color: #006;
}


/* for Ui design and responsivness testing */
.bgRed {
  background-color: red;
}
.bgBlue {
  background-color: blue;
}
.bgGreen {
  background-color: green;
}
.pd-t-50{
  padding-top:50px !important;
}
.mg-t-50{
  padding-top:50px !important;
}
.m-14{
  margin: 14px !important;
}
.p-28{
  padding:28px !important;
}
.p-14{
  padding:14px !important;
}
.p-l-14{
  padding:14px !important;
}
.displayCenter{
  display:flex !important;
  align-items:center !important;
  justify-content:center !important;
}
.m-t0{
  margin-top: 0px !important;
}
.cartPrice{
  font-size: medium;
  font-weight: bold;
}
.cartname{
  font-size: medium;
  /* font-weight: bold; */
  color: #606671;
  margin-top: 5px;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/* Call this to make a fade in animation to any div */
.fade-in-animate{
  opacity: 0; 
  animation: fade-in 1s ease-in-out forwards;
}