// .logininputContainer{
//     height: 150px;
// }
.signupLink{
   color: #2A64BA;
   font-weight: bold;
   cursor: pointer;
}
.signupLink:hover{
    color: #4185ea;
 }
 .css-qiwgdb.css-qiwgdb.css-qiwgdb{
   padding-right: 0px !important;
 }
 .pwdInput{
   min-width: 275px !important;
 }