.settingsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // max-height: 50vh;
  &.subContainer {
    max-height: calc(100vh - 110px);
  }
}
.settingsProfileImageContainer {
  width: 90px;
  height: 90px !important;
  background-color: gray;
  border-radius: 25px;
  margin-top: 36px;
}
.settingMenuContainer {
  width: 45vw;
  height: 100px;
  border: 2px solid #f1f2f4;
  border-radius: 8px;
  margin-bottom: 18px;
  padding: 28px;
  // max-height: 50vh;
}
.settingMenuContainerHeader {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #0e2744;
}
.settingMenuContainerLabel {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #abb4bd;
}

.settingsCardContaianer {
  // max-height: calc(100vh - 100px);
  &.subSettingsOptionsContainer {
    border: 2px solid #abb4bd;
    border-radius: 8px;
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // max-height: 100%;
  }
}

.profileName {
  font-weight: 500;
  font-size: 28px;
  text-align: center;
  color: #0e2744;
}
.listLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #abb4bd;
  margin-bottom: 36px;
  &.a{
    font-weight: 400;
font-size: 14px;
line-height: 20px;
  }
}
.settingsLayoutCardContainer {
  border-radius: 12px;
  cursor: pointer;
  width: 45vw;
  height: 100px;
  border: 2px solid #f1f2f4;
  margin-bottom: 18px;
  padding: 28px;
  // max-height: 50vh;
}
.settingsSubpageContainer {
  // min-height: calc(100vh - 100px);
  max-width: 100vw;
}
.subSettingsHeader {
  text-align: left;
}
.subSettingsContainer {
  // width: 35vw;
}
.profileStandard {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  color: #0e2744;

  opacity: 0.4;
}
.settingsSubFooter {
  margin-top: 25px !important;
  width: 300px;
  text-align: right;
  margin-bottom: 25px;
}
.SettingsProfileContainer {
  max-height: 100vh !important;
}
.backToSettings {
  position: absolute;
  // margin-top: 15px;
  margin-left: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
.invertFavArrow {
  transform: rotate(180deg);
}
.backToSettingslabel {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  /* identical to box height, or 111% */

  color: #0e2744;
  padding: 25px;
}
.settingsCancelButton {
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  color: #0e2744 !important;
  margin-right: 15px !important;
}
.settingsSaveButton {
  background: #0e2744 !important;
  border-radius: 23.5px !important;
}

.userBasicInfoContainer {
  border: 2px solid #f8f8fa;
  height: 140px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 25px;
  &.mt-28 {
    margin-top: 28px;
    height: auto;
  }
}
.editBtn {
  // width: px;
  // height: 39px;
  background: #0e2744;
  border-radius: 23.5px;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  padding: 5px;
  cursor: pointer;
}
.classUpgradeFooter{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.pwdInputForUpgrade{
  // width: 100% !important;
}