.borderLine {
    width: 100%;
    border-bottom: 1px solid #8A8A8C;
    justify-content: end;
    padding: 5px;
    color: #8A8A8C;
}

.height35 {
    height: 35px !important;
}

.subjectLabelInCart {
    width: 69px;
    text-align: center;
    height: 25px;
    font-weight: 600;
    font-size: 9px;
    background-color: #034ea2;
    color: white;
    border-radius: 5px;
    padding-top: 6px;
    // padding: 8px 10px;
}

.removeFromCart {
    width: 100px;
    height: 35px;
    // padding: 5px;
    background-color: #f45353;
    font-size: 14px;
    color: white;
    // padding-top: 10px;
    border-radius: 25px;
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.removeFromCart:active{
    background-color: #f61e1e;
    box-shadow: inset 0 0 10px #0f0f0f;
    
}

/* Define the animation properties*/
@keyframes slideOut {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateX(50px);
        /* Move the component right while fading out */
    }
}

/* Apply the animation to the component */
.slide-out {
    animation: slideOut 0.6s ease-out;
}

.cart-card {
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    box-sizing: border-box;
    // height: 400px !important;
    // max-height: 85vh !important;
    height: 85vh !important;
  }
  .buynow{
    background-color: #579536;
    margin-top: 0px;
  }
  .buynow:hover{
    background-color: #71c345;
    transition: background-color 0.5s ease;

  }
  .cartAmountContainer{
    // margin-top: 55px !important;
  }
  .star-container {
    perspective: 50px; /* Added for 3D perspective effect */
    animation: rotateStarX 2s linear infinite;
  }
  
  .star {
    font-size: 18px;
  }
  
  @keyframes rotateStarX {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }

  .seperator{
    width: 100%;
    margin-top: 55px;
    border-bottom: 2px solid rgb(110, 107, 107);
  }