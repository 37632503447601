.overallReportContainer {
  padding: 30px;
  margin-top: 300px;
  width: 100vw !important;
  cursor: pointer;
  border: 1px solid black;
}
.hedderRow {
  padding: 5;
}
.hedderCol {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 20px;
  color: #0e2744 !important;
  opacity: 0.8;
}
.wrong{
  background-color: rgb(255, 130, 130);
}
.right{
  background-color: rgb(141, 255, 151);
}