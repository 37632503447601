.studentVideoContainer {
  // background-color: red;
  // width: 320;
  height: 17vh !important; 
}
.tuitionMainContainer {
  min-height: 98vh !important;
  background-color: #f7f7f9;
}
.userVideoContainer {
  border-radius: 6px;
  height: 18vh;
}
.chatContainer {
  // margin-top: 25vh !important;
  border-radius: 14;
  // z-index: 99;
}
.whiteboardContainer {
  height: 80vh !important;
  margin-left: 5px !important;
  // width: 100% !important;
  border-radius: 8px;
  // background-color: azure;
  // background-color:blue;
}
.containerB {
  background-color: #f7f7f9;
  border: 2px solid #e9ebee;
  border-radius: 6px;
  padding: 5px;
  margin-left: 7px !important;
  height: 80vh !important;
}
.controlPannel {
  margin-left: 5px !important;
  border-radius: 8px;
  // background-color: grey;
}
.messageContainer {
  height: 35vh !important;
}
.messageInput {
  padding: 10px;
  //  background-color: ;
  max-width: 17vw !important;
  border-radius: 15px;
}
.myMessage {
  text-align: right;
  padding: 10px;
  background-color: aquamarine;
  height: auto;
}
.chatWindow {
  display: grid;

  background: rgb(233, 229, 229);
  border-radius: 5px;
  overflow-y: scroll;
  height: 44vh !important;
}

.chat {
  list-style: none;
  background: none;
  margin: 0;
  padding: 0 0 50px 0;
  margin-top: 10px;
  margin-bottom: 10px;
  max-height: 75vh;
  overflow: scroll;
  scroll-behavior: smooth;
}
.chat li {
  padding: 0.5rem;
  overflow: hidden;
  display: flex;
}
.chat .avatar {
  position: relative;
  display: block;
  z-index: 2;
}
.chat .avatar img {
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.chat .uid img {
  background-color: rgba(255, 255, 255, 0.9);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.chat .day {
  position: relative;
  display: block;
  text-align: center;
  color: #c0c0c0;
  height: 20px;
  text-shadow: 7px 0px 0px #e5e5e5, 6px 0px 0px #e5e5e5, 5px 0px 0px #e5e5e5,
    4px 0px 0px #e5e5e5, 3px 0px 0px #e5e5e5, 2px 0px 0px #e5e5e5,
    1px 0px 0px #e5e5e5, 1px 0px 0px #e5e5e5, 0px 0px 0px #e5e5e5,
    -1px 0px 0px #e5e5e5, -2px 0px 0px #e5e5e5, -3px 0px 0px #e5e5e5,
    -4px 0px 0px #e5e5e5, -5px 0px 0px #e5e5e5, -6px 0px 0px #e5e5e5,
    -7px 0px 0px #e5e5e5;
  box-shadow: inset 20px 0px 0px #e5e5e5, inset -20px 0px 0px #e5e5e5,
    inset 0px -2px 0px #d7d7d7;
  line-height: 38px;
  margin-top: 5px;
  margin-bottom: 20px;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.other .msg {
  order: 1;
  border-top-left-radius: 0px;
  box-shadow: -1px 2px 0px #d4d4d4;
}
.other:before {
  content: "";
  position: relative;
  top: 0px;
  right: 0px;
  left: 40px;
  width: 0px;
  height: 0px;
  border: 5px solid #fff;
  border-left-color: transparent;
  border-bottom-color: transparent;
}
.self {
  justify-content: flex-end;
  align-items: flex-end;
  overflow-wrap: anywhere;
}
.self .msg {
  order: 1;
  border-bottom-right-radius: 0px;
  box-shadow: 1px 2px 0px #d4d4d4;
}
.self .avatar {
  order: 2;
}
.self .avatar:after {
  content: "";
  position: relative;
  display: inline-block;
  bottom: 19px;
  right: 0px;
  width: 0px;
  height: 0px;
  border: 5px solid #fff;
  border-right-color: transparent;
  border-top-color: transparent;
  box-shadow: 0px 2px 0px #d4d4d4;
}
.msg {
  background: white;
  min-width: fit-content;
  padding: 5px;
  border-radius: 10px;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.07);
}
.msg p {
  font-size: 0.8rem;
  margin: 0 0 0.2rem 0;
  color: rgb(81, 84, 255);
}
.msg img {
  position: relative;
  display: block;
  width: 450px;
  border-radius: 5px;
  box-shadow: 0px 0px 3px #eee;
  transition: all 0.4s cubic-bezier(0.565, -0.26, 0.255, 1.41);
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
@media screen and (max-width: 800px) {
  .msg img {
    width: 300px;
  }
}
@media screen and (max-width: 550px) {
  .msg img {
    width: 200px;
  }
}
.msg time {
  font-size: 0.7rem;
  color: #ccc;
  margin-top: 3px;
  float: right;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.msg time:before {
  content: " ";
  color: #ddd;
  font-family: FontAwesome;
  display: inline-block;
  margin-right: 4px;
}
// ::-webkit-scrollbar {
//   min-width: 12px;
//   width: 12px;
//   max-width: 12px;
//   min-height: 12px;
//   height: 12px;
//   max-height: 12px;
//   background: #e5e5e5;
// }
// ::-webkit-scrollbar-thumb {
//   background: rgb(48, 87, 158);
//   border: none;
//   border-radius: 100px;
//   border: solid 3px #e5e5e5;
//   box-shadow: inset 0px 0px 3px #999;
// }
// ::-webkit-scrollbar-thumb:hover {
//   background: #b0b0b0;
//   box-shadow: inset 0px 0px 3px #888;
// }
// ::-webkit-scrollbar-thumb:active {
//   background: #aaa;
//   box-shadow: inset 0px 0px 3px #7f7f7f;
// }
// ::-webkit-scrollbar-button {
//   display: block;
//   height: 26px;
// }
/* T Y P E */
input.textarea {
  width: 100%;
  height: 50px;
  background: rgb(233, 229, 229);
  border: none;
  outline: none;
  padding-left: 55px;
  padding-right: 55px;
  color: #666;
  font-weight: 400;
}
.message {
  font-size: 12-px !important;
}
.studentsVideoContainer {
  height: 60vh !important;
  overflow: overlay;
  border-radius: 6px;
  background-color: #f7f7f9;
}
.mesasageInputContainer {
  // background-color: #666;
  position: absolute;
  max-height: 75vh !important;
  max-width: 19vw !important;
  margin-top: 45vh !important;
}
.timeLabel {
  font-weight: 400;
  font-size: 16px;
  // color: #666;
  margin-left: 18px !important;
  margin-top: 30px !important;
}
.actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 14px !important;
  cursor: pointer;
}
.leaveButton {
  font-size: small;
  width: 109px;
  height: 33px;
  background-color: #ff6868;
  color: #0e2744;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  border-radius: 8px;
  margin-top: 5px;
  cursor: pointer;
  &.green{
  background-color: #6dff68;

  }
}
.leaveButton:hover {
  background-color: #ffa0a0;
  transition: background-color 0.5s ease;
}

.waitingButton{
  font-size: small;
  width: auto;
  height: 43px;
  background-color: #6dff68;
  padding: 5px;
  color: #0e2744;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  border-radius: 8px;
  margin-top: 17px;
  cursor: pointer;
}
.tuitionLandingPageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.leavebuttonContainer {
  height: 80px;
}
.centerCard {
  width: 50vw;
  height: 50vh;
  border: 2px solid #b3d7ff;
  border-radius: 14px;
  box-shadow: 5px 2px 2px #73b0f7;
}
.pids-wrapper {
  width: 100%;
}
.pid {
  width: calc(10% - 10px);
  height: 10px;
  display: inline-block;
  margin: 5px;
}
.sendButton {
  padding-top: 18px;
}
#visualizer {
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: 25vh;
  width: 28vw;
  margin-top: 8px;
}
.networkContainer {
  align-items: "center";
  justify-contets: "center";
}
.align {
  margin-left: 55px;
}
.deviceRotate {
position: absolute;
justify-content: center;
text-align: center;
padding-top: 45vh;
padding: 10px;
}
.overWhiteboard{
  background-color: red;
  opacity: 0.5;
  z-index: 999 !important;
}
.chapternameContainer {
  display: flex;
  align-items: center;
  justify-content: left;
}
