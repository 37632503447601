.chooseLabel {
  font-weight: 400;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #0e2744;
}
.clContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
.childName {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #0e2744;
  text-align: center;
  opacity: 0.86;
}
.classLabel{
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    
    color: #0E2744;
    
    opacity: 0.5;
}