.signupLayout {
  width: 100vw;
  height: 100vh;
  //   background-color: rgb(156, 156, 156);
  background-color: rgb(0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;

  &.stage3 {
    background-color: #ffffff !important;
  }
}

.signupCard {
  width: 30vw;
  height: 90vh;
  min-height: 90vh;
  background-color: white;
  border-radius: 16px;
  float: center;
  // display: flex;
  justify-content: center;
  padding: 15px;
  overflow-y: auto;
  &.stage3 {
    background-color: #ffffff !important;
    min-height: 100vh;
  }
}

.SignUpdbLogo {
  margin-top: 5vh;
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.inputsLayout {
  // margin-top: 18vh;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  color: #0e2744;
  .label {
    font-size: 16px;
    opacity: 0.68;
    margin-top: 8px;
  }
  margin-top: 36px;
}
.phonePrefix {
  min-width: 80px !important;
  margin-right: 10px;
  border-radius: 8px;
  font-size: 14px;
}
// phone number input
.css-1q6at85-MuiInputBase-root-MuiOutlinedInput-root {
  padding-left: 0px !important;
  max-width: 300px;
}
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding-right: 0px;
  font-size: 14px;
  font-weight: 600;
}
// phone number
.css-1o9s3wi-MuiInputBase-input-MuiOutlinedInput-input {
  margin-left: 10px !important;
}
.css-1ixds2g {
  margin-left: 10px !important;
}
.inputContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
}
.nameInput {
  min-width: 300px !important;
}
.tandcGrid {
  margin-top: 36px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.toggle{
    margin-top: 0px;
  }
  cursor: pointer;
}
.tandc {
  text-align: center;
  max-width: 200px;
  font-size: 12px;
  font-weight: 400;
  color: #5b6c80;
  .tandcLinks {
    color: #034ea2;
    cursor: pointer;
  }
}
.stageButton {
  min-width: 300px;
  height: 47px;
  background: #0e2744;
  border-radius: 23.5px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  &.outlined{
    border: 2px solid #0e2744;
    background:white;
    color: #0e2744;
  }
}
.stageButton:hover {
  background-color: #004386;
  transition: background-color 0.3s ease;
  &.outlined{
 
    color: white;
  }
}
.stageButton:active{
  background-color: #0e2744;
  transition: background-color 0.3s ease;
  box-shadow: 1px 1px 2px grey;
}
.authCodeClass {
  margin: 0.5em;
  font-size: 18px;
  text-align: center;
  width: 41px;
  height: 56px;
  background: #ffffff;
  border: 2px solid #f1f2f4;
  border-radius: 8px;
}
.authCodeClass:hover {
  border: 2px solid #2769b5;
}
.authCodeClassErr {
  margin: 0.5em;
  font-size: 18px;
  text-align: center;
  width: 41px;
  height: 56px;
  background: #ffffff;
  border: 2px solid #ff0000;
  border-radius: 8px;
}
.OTPContainer {
  width: 100%;
  margin-top: 36px;
  margin-bottom: 36px;
  text-align: center;
}
.resendCodeText {
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #034ea2;
  margin-top: 38px;
  cursor: pointer;
}
.stage3Container {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
}
.signupHeadText {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  /* or 133% */

  text-align: center;

  color: #0e2744;
}
.logoInSignup {
  position: absolute;
}
