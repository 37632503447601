.chapterListContainer {
  padding: 24px;
  background-color: #f7f7f9;
  border-radius: 8px;
  &.details {
    border: 2px solid #e2e2e2;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 175px;
  }
}
.PreviewPageContainer {
  padding: 24px;
}
.assessMeChapterText {
  font-weight: 600;
  font-size: 14px;
  line-height: 35px;
  color: #0e2744;
  opacity: 0.6;
  &.chapters {
    opacity: 0.8;
    line-height: 25px;
  }
}
.hedderTextGI {
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #0e2744;
  opacity: 0.8;
}
ul.a {
  list-style-type: disc;
  color: #0e2744;
  opacity: 0.8;
  font-weight: 500;
  font-size: 16px;
  line-height: 40px;
}
