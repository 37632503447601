.QDContainer {
  border: 2px solid #d8dce1;
  border-radius: 8px;
  width: 100%;
  padding: 5px;
  height: 500px !important;
}
.labels {
  font-size: 14px;
  line-height: 20px;
  color: #0e2744;
  opacity: 0.8;
  &.label1 {
    font-weight: 500;
    line-height: 35px;
    opacity: 0.4;
  }
  &.label2 {
    font-weight: 500;
    line-height: 30px;
  }
  &.label3 {
    font-weight: 700;
    line-height: 30px;
  }
  &.instructions {
    font-weight: 600;
    color: #185bdc;
    line-height: 40px;
    opacity: 0.4;
  }
}
.palette {
  text-align: center;
  width: 34px;
  height: 34px;
  // background-color: #d6d6d6;
  border: 1px solid #cfd4da;
  line-height: 34px;
  border-radius: 25px;
  margin-top: 20px;
  font-weight: 600;
  font-size: 16px;
  // line-height: 12px;
  color: #0e2744;
  opacity: 0.8;
  cursor: pointer;
  &.notAttempted {
    background: #c3cfdb;
  }
  &.answered {
    background: #a1f084;
  }
  &.notAnswered {
    background: #ff7d7d;
  }
  &.review {
    background: #4ba0ff;
  }
  &.marked{
    background: #0077ff;

  }
}
.palletteContainer {
  width: "100%";
  padding-left: "20px" !important;
}
.greenCircle {
  height: 10px;
  width: 10px;
  background: #8bfc62;
  border-radius: 7px;
  margin-top: 2px !important;
}
.redCircle {
  height: 10px;
  width: 10px;
  background: #ff7d7d;
  border-radius: 7px;
  margin-top: 2px !important;
}
.greyCircle {
  width: 10px;
  height: 10px;
  background: #c3cfdb;
  border-radius: 7px;
  margin-top: 2px !important;
}
.blueCircle {
  width: 10px;
  height: 10px;
  background: #4ba0ff;
  border-radius: 7px;
  margin-top: 2px !important;
}
.answeredLabel {
  font-weight: 500;
  font-size: 10px;
  color: #0e2744;
  opacity: 0.8;
}
.timerBg {
  width: 26px;
  height: 22px;
  border-radius: 18px;
  background-color: #c7e1ff;
  text-align: center;
  padding-top: 4px;
  padding-left: 1px;
}
.timerLabel {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #0e2744;
  opacity: 0.8;
  padding-left: 8px;
}
.questionNo {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #0e2744;
  opacity: 0.6;
}
.nextButton {
  min-width: 112px;
  height: 27px;
  background: #0e2744;
  border-radius: 23.5px;
  font-weight: 700;
  padding-top: 10px;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  &.previous {
    background: #1f67c0;
  }
  &.disabled{
    background: rgb(123, 123, 123);
  }
}
.clearButton {
  width: 112px;
  height: 27px;
  border: 1px solid #0e2744;
  border-radius: 23.5px;
  font-weight: 700;
  padding-top: 10px;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #0e2744;
  cursor: pointer;
}
.dataCard {
  background-color: #0e2744;
  color: white;
  // margin: 10px;
  // padding: 10px;
  border-radius: 8px;
}
.optionButton {
  border: 1px solid #0e2744;
  border-radius: 23.5px;
  font-weight: 700;
  padding-top: 10px;
  padding: 20px;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #0e2744;
  cursor: pointer;
}
.resulttopicName {
  padding: 30px;
  font-size: 18px;
  font-weight: bold;
}
.reportSubContainer1 {
  background: #f7f7f9;
  border-radius: 8px;
  padding: 25px;
}
.reportsLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0e2744;
  opacity: 0.4;
}
.reportsValue {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #0e2744;

  opacity: 0.8;
}
.percentageBar {
  width: 250px;
}
.assessmentDate {
  margin-left: 25px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  color: #0e2744;

  opacity: 0.8;
}
.reportLabel {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  /* identical to box height, or 167% */

  color: #0e2744;
  margin-bottom: 12px !important;

  opacity: 0.6;
}
.reportsValues {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 22px;
  /* identical to box height, or 69% */

  color: #0e2744;

  opacity: 0.8;
}
.reportsMainContainer {
  margin-top: "20px" !important;
  padding: "20px";
  padding-left: "25px";
  max-width: 100%;
  min-width: 550px;
  // overflow: scroll;
  //  opacity: 0.16;
  border: 2px solid #d8d8fe;
  border-radius: 8px;
  display: flex;
  // align-items: center;
  margin-left: 2vw !important;
  padding: 15px 0px;
}
