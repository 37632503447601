.contentsPageLayout {
  margin-top: 15px;
  height: calc(100vh - 110px);
  // width: calc(100vw - 110px);
}
.learnOnLayout1 {
  border: 1px solid #eeeff1;
  border-radius: 8px;
}
.learnOnChapterName {
  font-weight: 600;
  color: #0e2744;
  opacity: 0.6;
}
.learnOnChapter {
  font-weight: 500;
  // font-size: 1.2vw;

  color: #0e2744;
  opacity: 0.4;
}
.learnOnChapter:hover {
  cursor: pointer;
  text-decoration: underline;
}
.contentList {
  width: 100%;
  max-height: calc(100vh - 175px);
}
.eachContentCard {
  padding: 5px;
  align-items: center;
  margin-top: 25px;
  border-radius: 8px;
  border: 1px solid rgb(247, 244, 244);
  cursor: pointer;
}
.eachContentL1 {
  height: 35px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  background-color: #f3e3e4;
  &.eachContentL1 {
    background-color: #dbdaff;
  }
}
.eachContentL2 {
  padding: 0px 0px 0px 15px;
}
.eachContentL2TopicName {
  font-weight: 700;
  font-size: 16px;
  color: #0e2744;

  opacity: 0.8;
}
.eachContentL2Duration {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #3e5269;
  opacity: 0.5;
}
.contentContainer {
  max-width: 66vw !important;
}
.NoDataLearnOn {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  font-size: 35px;
  color: #0e2744;
}
.playerOverlay {

  
}
