.question {
  margin-top: 29px;
  font-weight: 600;
  font-size: 16px;
  line-height: 38px;
  color: #0e2744;
  opacity: 0.64;
  user-select: none;
}
.optionsContainer {
//   opacity: 0.16;
user-select: none;
  border: 2px solid #D8DCE1;
  border-radius: 8px;
  padding: 10px;
  margin-top: 20px;
  margin-bottom:15px !important;

  &.mtf {
    padding:5px;
    display:flex;
  margin-top: 35px;

    // align-items: flex-end;
    // width: 15vw;
    // margin-top: 5.5vh;
  }
  &.right{
    background-color: rgb(162, 255, 162);
  }
  &.wrong{
    background-color: rgb(253, 173, 173);

  }
}
.optionsLabel {
  font-weight: 500;
  font-size: 18px;
  line-height: 50px;
  color: #0e2744 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mcqOption{
  margin-top: 15px !important;
}
.mtfOptionsContainer{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  user-select: none;
}
.mtfOptions{
  // width: 240px;
  // margin:10px !important;
  margin-bottom: 10px !important;
  user-select: none;
}
.mtfQuestionOptions{
  // width: 240px;
  height:56px;
  border: 2px solid #C4C4C4;
  border-radius: 4px;
  padding-left: 15px;
  margin-bottom: 10px !important;
 &.select{
  border: none;
  padding-left: 0px;
 }
 &.pt{
  padding-top: 15px;
  padding-left: 20px;
 }
 &.a{
  padding-top: 12px;
  padding-left: 12px;
  height: 100%;

 }
  // margin:15px !important;

  // margin-bottom: 6.5vh !important;
}
.inbtw{
  border-bottom: 3px dashed  #CFD9E2;
  // margin-bottom: 55px !important;
  height: 30px;
  margin-bottom: 35px !important;
}
.qnIndex{
  color: #0e2744 !important;
  position: absolute;
  margin-top: 15px;
}