// .react-horizontal-scrolling-menu--item:first-child {
// margin-left: 50px;
// }
$small: 300px;
$medium: 900px;
.react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none; /* Chrome*/
}
.recommendedCard {
  margin-top: 15px;
  min-width: 250px !important;
  width: 22.5vw !important;
  height: 262px !important;
  margin-right: 10px;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  padding: 20px;
  border: 2px solid #f1f2f4;
  margin-bottom: 20px;
  &.completed {
    height: 210px !important;
  }
}
.recommendedCard:hover {
  box-shadow: 1px 1px 3px grey;
  transition: box-shadow 0.5s ease;
}
.nextPrevButton {
  // background-color: #0e2744 !important;
  width: 50px;
  height: 50px;
  border-radius: 25px !important;
  color: white;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  padding-top: 10px;
  margin-top: 5rem;
  cursor: "pointer";
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.recommendedLabelText1 {
  font-weight: 700;
  font-size: 18px;
  color: #0e2744;
  margin-left: 20px;
}
.recommendedLabelText2 {
  font-weight: 400;
  font-size: 14px;
  color: #0e2744;
  margin-left: 20px;
}
.subLogoBackdrop {
  height: 28px;
  width: 28px;
  border-radius: 25px;
}
.subjectLogo {
  position: absolute;
  margin-left: 4px;
  margin-top: 5px;
}
.availableSeats {
  width: 69px;
  text-align: center;
  // height: 22px;
  font-weight: 600;
  font-size: 9px;
  background-color: #034ea2;
  color: white;
  border-radius: 5px;
  padding: 8px 10px;
}
.courseName {
  font-weight: 700;
  font-size: 18px;
  color: #0e2744;
  padding-top: 20px;
  width: 100%;
  height: 85px;
}
.courseDetailsLabel {
  font-weight: 500;
  font-size: 12px;
  color: #0e2744;
  opacity: 0.4;
  padding-top: 20px;
}
.courseDetailsValue {
  font-weight: 600;
  font-size: 13px;
  color: #0e2744;
}
.moreDetails {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #3970ae;
  text-decoration-line: underline;
  padding-left: 26px;
  margin-top: 10px;
  cursor: pointer;
}
.moreDetails:active{
  box-shadow: inset 0 0 10px #0f0f0f;
}
.cardFooter {
  margin-top: 40px;
  &.completedFooter {
    margin-top: 20px;
  }
}
.joinButton {
  width: 115px;
  // height: 15px;
  color: #0e2744;
  background: #fefefe;
  border-radius: 23.5px;
  text-align: center;
  padding: 9px 0px;
  font-weight: 700;
  font-size: 12px;
  border: 1px solid #90f4ff;
  cursor: pointer;
  &.now {
    background: green;
    color: white;
  }
}
.joinButton:hover {
  background-color: #90f4ff !important;
  opacity: 1;
  border: 1px solid #90f4ff;
  transition: background-color 0.5s ease;
  &.now {
    color: rgb(32, 32, 32);
  }
}
.joinButton:active {
  color: #0e2744;
  box-shadow: inset 0 0 10px #0f0f0f;
}
.closeButton {
  width: 115px;
  color: #0e2744;
  border-radius: 23.5px;
  text-align: center;
  padding: 9px 0px;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
  background-color: #eeeff1;
}
.closeButton:hover {
  background-color: #e5e4e4 !important;
  transition: background-color 0.5s ease;
}
.assessmeLayoutCardContainer {
  width: 90%;
  height: 115px;
  margin-left: 5%;
  margin-top: 20px;
  background: #ffffff;
  border-radius: 12px;
  cursor: pointer;
}
.assessmeLayoutCardContainer:hover {
  box-shadow: 1px 1px 3px grey;
  transition: box-shadow 0.5s ease;
}
.assessmeLayoutContainer1 {
  width: 64px;
  height: 49px;
  background: #f7f7fa;
  border-radius: 6px;
  margin: 24px 0px 0px 18px;
  text-align: center;
  padding-top: 12px;
  padding-left: 18px;
}

.assessmeLayoutContainer2 {
  width: 100%;
  height: 100%;
}
.chapterName {
  font-weight: 700;
  font-size: 14px;
  margin-top: 24px;
  margin-left: 5px;
  height: 40px;
  width: 96%;
}

.subjectName {
  color: #0e2744;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  opacity: 0.4;
  margin-top: 4px;
  margin-left: 5px;
}
.assessmeLayoutContainer3 {
  margin-top: 36px;
}

.subjectTag {
  margin-left: 1vw;
  height: 90px;
  // padding-top: 10px;
  width: 100px;
  border-radius: 50px;
  // text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.physics {
    height: 80px;
    padding-top: 5;
    background-color: #fcecff;
  }
  &.chemistry {
    height: 80px;
    // padding-top: 20px;
    background-color: #fcecff;
  }
  &.layer {
    padding-top: 20px;
    height: 70px;
    background-color: #fff6a3;
    &.malayalam2 {
      background-color: #c8fffa;
    }
    &.hindi {
      background-color: #ffb4c6;
    }
    &.ss {
      height: 83px;
      padding-top: 17px;
      padding-left: 4px;
      width: 96px;
      background-color: #fccdb2;
    }
  }
}
.subjectTagContainer {
//  display: flex;
//  align-items: center;
//  justify-content: center;
  // padding: 0px 20px 20px 20px;
  // border: 1px solid #004386;
}
.subjectContainer:hover {
  border-radius: 8px;
  cursor: pointer;
  background-color:rgb(248, 248, 248) ;
  box-shadow: 1px 1px 3px grey;
  transition: box-shadow 0.5s ease;
  transition: background-color 0.5s ease;
}
.subjectLabel {
  width: 120px;
  margin-top: 12px;
  text-align: center;
}
.subjectLayoutContainer {
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 50px;
  margin-top: 50px;
}

.subjectCard {
  width: 288px;
  height: 125px;
  border: 2px solid #eeeff1;
  border-radius: 8px;
  padding: 14px;
  margin-top: 45px;
  cursor: pointer;
}
.subjectCard:hover {
  box-shadow: 1px 1px 3px grey;
  transition: box-shadow 0.5s ease;
}
.subjectCardLabel {
  font-weight: 700;
  font-size: 16px;
  color: #0e2744;
  padding-top: 16px;
  max-width: 250px;
}
.subjectCardImage {
  width: 52px;
  height: 52px;
  border-radius: 25px;
  // background-color: #b86ac5;
}
.lockIcon {
  position: absolute;
  margin-left: 255px;
}

.sessionDetailChips {
  background: #f5faff;
  padding: 2px;
  font-size: 0.8rem;
  // opacity: 0.38;
  border-radius: 3px;
  color: #7f9fc1;
}
.modalClosebutton {
  position: absolute;
  margin-left: 85%;
  margin-top: 0;
}
.reviewContainer {
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  max-height: 45vh;
  padding: 5px;
  overflow-y: auto;
  margin-top: 5px;
}
.reviewCards {
  height: auto;
  padding: 5px;
}
.starContainer {
  background: #ffffff;
  border: 1px solid #0e2744;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.reviewLabel {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0e2744;
  &.teacher {
    font-weight: 600;
    font-size: 18px;
  }
}
.daysAgoText {
  font-weight: 500;
  font-size: 10px;
  color: #0e2744;
  opacity: 0.4;
  &.p {
    padding: 5px;
  }
  &.teacher {
    font-size: 12px;
  }
}
.proceedButton {
  background-color: #0e2744 !important;
  width: 140px;
  height: 35px;
  border-radius: 23px !important;
  color: white;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  justify-content: center;
  padding-top: 10px;
  margin-top: 40px;
  cursor: pointer;
  z-index: 9;
}
.proceedButton:hover {
  background-color: #004386;
  transition: background-color 0.3s ease;
}
.proceedButton:active {
  background-color: #0e2744;
  transition: background-color 0.3s ease;
  box-shadow: 1px 1px 2px grey;
}
.noSessions {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.backgrounQuestionMark {
  background-color: #3970ae;
  width: 100px;
  height: 100px;
  text-align: center;
  border-radius: 50px;
  font-size: 70px;
  color: white;
}
.qnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 45%;
}
.messageLabel {
  font-weight: 600;
  font-size: 26px;
  line-height: 38px;
  /* or 146% */
width: 100%;
  text-align: center;

  color: #0e2744;

  opacity: 0.92;
}
.reviewBtn {
  width: 112px;
  height: 39px;
  color: #0e2744;

  background: #ffffff;
  border: 1px solid #0e2744;
  border-radius: 23.5px;
}

.subjectLogo {
  width: 80px;
}
.dzu-dropzone {
  overflow: hidden !important;
}
.topicHead{
  font-size: 18px;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  color: white;
}
.rankList{
  background-color: #0475B9;
  padding: 15px;
  border-radius: 12px;
  height: 50px !important;
  align-items: center;
  justify-content: center;
  margin-top: 5px !important;
}
.suzuki{
  color:#ADE3F5
}
.rlistContainer{
  align-items: center;
  justify-content: center;

}

