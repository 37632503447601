.freeTrialContainer {
  height: '100%';
  width: 70%;
  background-color: #CDE3C0;
  position: absolute;
  text-align: center;
  margin-left: 7%;
  // background: #e3e7eb;
  border-radius: 12px;
  padding: 10px;
  margin-top: 1vh;
  cursor: pointer;
}
.freeTrialContainer:hover {
  background-color: #d4f3c2;
  // background-color: #CDE3C0;
  transition: background-color 0.5s ease;
}

.headLabel {
  // font-family: 'Plus Jakarta Sans';
  margin-top: 5px;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #0e2744;
}
.subLabel {
  font-weight: 700;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
  &.Upgradebutton{
    padding: 10px 5px;
    background: #EEF1F3;
    border: 1px solid #0E2744;
    border-radius: 23.5px;
    margin-top: 15px;
  }
}
.upgradeContainer {
  background: #e3e7eb;
}
