.topBarContainer {
  width: 100%;
  height: 60px;
  border-bottom: 2px solid #cfcfcf;
  padding: 22px 0px 0px 15px;
  max-width: calc(100vw - 15px);
  // position: fixed;
}
.topBarContainerNoBorder {
  width: 100%;
  height: 60px;
  // border-bottom:  2px solid #cfcfcf;
  padding: 22px 0px 0px 15px;
  max-width: calc(100vw - 15px);
}
.profileAvatar {
  float: right;
}
.floatingLogo {
  position: absolute;
  padding: 16px 0px 0px 14px;
}
.css-cveggr-MuiListItemIcon-root {
  min-width: 25px !important;
}
.searchButtton{
  height: 50px;
  margin-top: 16px !important;
}
.persistentDrawer{
  position: absolute;
  z-index: 9;
  background-color: #cfcfcf;
}