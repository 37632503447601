.dashSearch {
  width: 50vw;
  padding-left: 1.2rem !important;
  margin-top: 16px !important;
  position:relative;
}
.dashLayout {
  height: 100%;
}
.notificationLogo {
  height: 36px !important;
  margin-top: 16px !important;
  margin-left: 5% !important;
}
.userProfleLogo {
  height: 36px !important;
  margin-top: 14px !important;
  margin-left: 20px !important;
}
.nameContainer {
  // padding-top: 48px;
  padding-left: 18px;
  .nameSpan {
    font-size: 28px;
    font-weight: 700;
  }
  .captchaSpan {
    font-size: 14px;
    font-weight: 500;
    color: #0e2744;
  }
  .assessmeNameSpan {
    font-size: 18px;
    font-weight: 700;
  }
  .assessmeCaptchaSpan {
  }
}
.layout1 {
  height: calc(100vh - 70px);
}

.layout2 {
  // overflow-y: scroll;
  // max-height: 100vh;
  // -ms-overflow-style: none; /* IE and Edge */
  // scrollbar-width: none; /* Firefox */
  // overflow-y: scroll;
}
// .layout2::-webkit-scrollbar {
//   display: none;
// }
.layout2Container {
  height: calc(100vh - 80px);
  background: rgba(238, 238, 245, 0.32);
  border-radius: 12px;
  margin-right: 10px;
}
.icon {
  padding-top: 15px;
  padding-left: 15px;
  position: absolute;

}

.input {
  border-radius: 8px;
  height: 50px;
  box-sizing:border-box;
  padding-left: 3vw;
  width: 50vw;
  font-size: 16px;
  color: #0e2744;
  border: 2px solid#D8DCE1;
}
.notFoundContainer{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.notFound404Text{
  font-size:5rem
}
.backTo{
  margin-top: 36px;
  padding: 10px 15px;
  background-color: #0e2744;
  color: white;
  border-radius: 25px;
}