.nameSpan {
    color: black;
    font-size: 0.875rem;
    font-weight: 700;
}
.iconDisabled{
    opacity : 0.5;
    // background-color: red;
    border-radius: 12px;
}
