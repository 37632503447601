.viewAllLablel {
  font-weight: 800;
  font-size: 13px;
  line-height: 16px;
  text-align: right;
  color: #3970ae;
  margin-left: 79% !important;
  cursor: pointer !important;
}

.backToLiveTuition {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #b3b3b3;
  opacity: 0.8;
  position: absolute;
  margin-top: 16px !important;
  margin-left: 4.1vw;
  cursor: pointer;
  text-decoration: underline;
}

.courseNameContainer {
  min-height: 142px;
  height: auto;
  padding-left: 28px;
  // padding: 28px;
  border: 2px solid #e7e9ec;
  border-radius: 8px;
}

.priceContainer {
  height: 142px;
  padding: 10px;
  border: 2px solid #e7e9ec;
  border-radius: 8px;
  // margin-left: 10px;
  align-content: center;
  // padding-left: 6rem;
  // padding-top: 28px;
  &.center{
justify-content: center;
font-weight: bold;
font-size: large;
color: #e44100;
  }
}

.detailsContainer {
  // height: 142px;
  padding: 10px;
  border: 2px solid #e7e9ec;
  border-radius: 8px;
  width: 100%;
}

.descriptionCntr {
  background-color: #f2f2f2;
  width: 99%;
  padding: 3px;
  border-radius: 8px;
}

.labels {
  &.sub {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #213853;
    opacity: 0.5;
    margin-top: 28px;
  }

  &.course {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #0e2744;
    opacity: 0.78;
  }

  &.date {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0e2744;
    opacity: 0.3;
  }

  &.amount {
    font-weight: 700 !important;
    font-size: 30px !important;
    color: #0e2744 !important;
    opacity: 0.8;
    // margin-left: 15px;
    // margin-bottom: 15px;
  }

  &.extraDetails {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    color: #0e2744;
  }

  &.sessionName {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #0e2744;
    opacity: 0.8;
    margin-left: 28px;
  }

  &.sessionTime {
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: #0e2744;
    opacity: 0.5;
    padding-left: 28px;
  }

  &.sessionDate {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #0e2744;
    opacity: 0.3;
    padding-left: 35px;
  }

  &.permonth {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    color: #0e2744;
  }

  &.TeacherName {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    color: #185bdc;
    opacity: 0.92;
  }
}

.buyNow {
  text-align: center;
  // position: absolute;
  width: 112px !important;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #0e2744;
  border-radius: 23.5px;
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 5.54vw;

  &.green {
    background: #00be3f;
  }
}

.buyNow:hover {
  background: #00be3f;
  transition: background-color 0.5s ease;
}

.batchList {
  width: 94%;
  padding: 10px;
  border-radius: 5px;
  background-color: #e7e9ec;
  margin-top: 15px;
  cursor: pointer;
}

.batchList:hover {
  box-shadow: 1px 1px 3px grey;
  transition: box-shadow 0.5s ease;
}

.batchListSelected {
  width: 94%;
  padding: 10px;
  border-radius: 5px;
  background-color: #76ff91;
  margin-top: 15px;
  cursor: pointer;
}

.sessionAccordian {
  margin-top: 15px;
  border: 2px solid #e7e9ec;
  border-radius: 8px;
}

.videoChangeButton {
  width: 100px;
  background-color: #7a7a7a;
  padding: 10px;
  margin: 5px;
  border-radius: 18px;
  display: flex;
  text-align: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  cursor: pointer;

  &.selected {
    background-color: #00C0F3;
  }

  &.selected:hover {
    background-color: #8fd2ff;
  }

  &.back {
    background-color: #0e2744;
  }

  &.back:hover {
    background-color: #164172;
  }
}

.videoChangeButton:hover {
  background-color: rgb(146, 146, 146);
}

.priceCard {
  width: 100%;
  height: 100%;
  border-radius: 14px;
  border: 2px solid #e7e9ec;
  margin: 10px;
  box-shadow: #00C0F3;
  display: flex;
  align-items: center;
  justify-content: center;

  &.ml5 {
    margin-left: 5px;
  }
}

.priceCard:hover {
  box-shadow: 1px 1px 3px grey;
  transition: box-shadow 0.5s ease;

}

.orLabel {
  padding-top: 46px !important;
}

.coursePrice {
  font-size: 20px;
}

.confirmation-header {
  color: #185bdc;
  font-size: 2em;
  font-weight: bold;
}

.confirmation-text {
  color: #185bdc;
  font-size: 1.5em;
}

.buyPrice {
  font-size: 32px;
  font-weight: 600;
  line-height: 1;
  color: #333;
}

.style_discount-detail-section {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.style_off-perc {
  font-size: 15px;
  font-weight: 700;
  line-height: 1.25;
  color: #4ebe85;
  margin-right: 8px;
}

.style_cut-price {
  opacity: .5;
  font-size: 18px;
  line-height: 1.11;
  color: #333;
  text-decoration: line-through;
}

.style_vsc-courses-buy-now {
  margin-top: 10px;
  padding-top: 15px;
  width: 100%;
  height: 36px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(38, 42, 255, 0.3);
  background-color: #4163fc;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 16px;
  cursor: pointer;
}

.style_vsc-courses-buy-now:hover {
  transition: background-color 0.5s ease;

  background-color: #00be3f;
}

.addCartButton {
  background-color: #FED60C;
  color: #806608;
}

.addCartButton:hover {
  color: #fff !important;
  background-color: #4163fc !important;
}

.telebox-titlebar-btns {
  display: none !important;
}
.existInCartButton {
  background-color: #00be3f !important;
  color: #fff !important;
}
.telebox-drag-area {
  display: none !important;

}

.telebox-max-titlebar-maximized {
  display: none !important;
}

.player-controller {
  display: none !important;
}

.vjs-p * {
  pointer-events: none !important;
}