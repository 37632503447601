@import url('https://fonts.googleapis.com/css?family=Lato&display=swap');
$backgroundColor: white;
$textcolor:#000000;
$borderLeftColor:#58b5fc;

.navbar-menu {
  height:100vh;
  background-color:$backgroundColor;
  box-shadow: 1px 1px 7px rgba(0,0,0,0.15);
  transition: all 0.5s ease;
  position: fixed;
  top: 0;
  left: 0;
}
.burger{
  display:flex;
  justify-content: flex-end;
}
.burger img {
  padding: 25px 8px 0px 0px;
  cursor: pointer;
}
.navbar__list {
  list-style-type: none;
  margin-left: -10px;
}
.navbar__li {
  display: inline-block;
  padding:0px 0px 0px 12px;
  position: relative;
  top:2.5px;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 38px;
  color: $textcolor;
  font-family: "Lato";
  font-weight: 400;
}
.navbar__li-box:first-child {
  margin-top:26px;
}
.navbar__li-box {
  height: 18px;
  margin-top:15px;
}
.navbar__li-box:hover {
  border-left: 7px solid $borderLeftColor;
  cursor: pointer;
}
.dbLogo{
  float: center;
  margin-top: 1rem;
  margin-right: 2rem;
}
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 320px;
    background-color: #fff;

    &__logo {
        display: grid;
        place-items: center;
        height: 120px;
        font-size: 1.5rem;
        font-weight: 700;
        font-family: 'Mochiy Pop P one', sans-serif;
    }

    &__menu {
        position: relative;

        &__item {
            display: flex;
            align-items: center;
            place-content: flex-start;
            padding: 1rem 3rem;
            font-size: 1.25rem;
            font-weight: 500;
            color: #555555;
            transition: color 0.3s ease-in-out;

            &.active {
                color: #fff;
            }

            &__icon {
                margin-right: 1rem;

                i {
                    font-size: 1.75rem;
                }
            }
        }

        &__indicator {
            position: absolute;
            top: 0;
            left: 50%;
            width: calc(100% - 40px);
            border-radius: 10px;
            background-color: #308efe;
            z-index: -1;
            transform: translateX(-50%);
            transition: 0.3s ease-in-out;
        }
    }
}